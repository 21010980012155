<!--/**-->
<!--* 自动生成 vue列表代码【模块名：快递绑定库位】-->
<!--* 生成日期：2024/05/30 11:55:52-->
<!--* 生成路径: src/pages/express_take_over/ExpressTakeOverList.vue-->
<!--* 生成人：管理员-->
<!--*/-->
<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='快递单号'>
              <el-input v-model='searchCondition.express_number' placeholder='快递单号' clearable></el-input>
            </el-form-item>
            <el-form-item label='库位编号'>
              <el-input v-model='searchCondition.whl_code' placeholder='库位编号' clearable></el-input>
            </el-form-item>
            <el-form-item label='负责人'>
              <el-input v-model='searchCondition.leader_name' placeholder='负责人' clearable></el-input>
            </el-form-item>
            <el-form-item label='创建人id'>
              <el-input v-model='searchCondition.created_by' placeholder='创建人id' clearable></el-input>
            </el-form-item>

            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                         @click='handleQuery'>搜索
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--        操作栏-->
        <el-row class='mb8' :gutter='10'>
          <el-col :span='1.5'>
            <el-button type='primary' icon='el-icon-plus' @click='handleAdd'>新增</el-button>
          </el-col>
          <el-col :span='1.5'>
            <el-button icon='el-icon-upload' @click='openImport=true'>导入</el-button>
          </el-col>
          <el-col :span='1.5'>
            <export-btn ref='refLogisticSubscribeExport' :can-export='hasAuthority("express_take_over_export")'
                        @export='handleExport'></export-btn>
          </el-col>
        </el-row>
        <!--    渲染表格-->
        <div class='default-table'>
          <MlPageTable :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :height='height'
                       highlight-current-row
                       :row-class-name='tableRowClassName'
                       @row-click='rowClickEvent'
                       @getList='getList'>
            <el-table-column align='center' width='120' label='操作'>
              <template slot-scope='{row,$index}'>
                <el-button type='text' icon='el-icon-edit' @click='handleEdit(row)'>修改</el-button>
                <el-button type='text' icon='el-icon-delete' @click='handleDelete(row,$index)'>删除</el-button>
              </template>
            </el-table-column>
          </MlPageTable>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'

export default {
  name: 'ExpressTakeOverList',
  components: { ExportBtn, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'express_take_over',
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      height: 800,
      searchCondition: {
        date_range: [],
        express_number: null,
        wh_id: null,
        whl_code: null,
        leader_id: null,
        leader_name: null,
        created_by: null
      },
      pageData: { current_page: 1, page_size: 100 },
      orderBy: { id: 'asc' },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns: [
        { hide: false, prop: 'id', label: '序号', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'express_number', label: '快递单号', width: 120, sortable: false, fixed: false },
        { hide: false, prop: '_wh_nm', label: '仓库id', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'whl_code', label: '库位编号', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'leader_name', label: '负责人', width: 120, sortable: false, fixed: false },
        { hide: false, prop: '_created_nm', label: '创建人', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'updated_at', label: '修改时间', width: 120, sortable: false, fixed: false }
      ],
      // 弹出层标题
      title: '添加/修改-快递绑定库位',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules: {
        express_number: [{ required: true, message: '快递单号不能为空', trigger: 'blur' }]
      },
      // 表单参数
      form: {},
      //是否显示弹出层:导入框
      openImport: false,
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:express_take_over',
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'date_range' && this.searchCondition[field]) {
          // console.log(Object.prototype.toString.call(this.searchCondition[field]))
          // console.log((this.searchCondition[field]).constructor)
          // console.log(typeof (this.searchCondition[field]))

          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getExpressTakeOverList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    delRow(id) {
      this.$api.deleteExpressTakeOver(id)
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.title = '添加-快递绑定库位'
      // let { info } = await this.$api.getExpressTakeOverInfo(row.id)
      this.form = {}
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.title = '修改-快递绑定库位'
      // let { info } = await this.$api.getExpressTakeOverInfo(row.id)
      this.form = { ...row }
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          this.$api.saveExpressTakeOver(this.form).then(id => {
            if (id) {
              if (!this.form.id)
                this.msgSuccess('新增成功')
              else
                this.msgSuccess('修改成功')

              this.form.id = id
              this.open = false
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })

        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
    },
    //导出表格
    async handleExport() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportExpressTakeOvers(searchCondition)
        let name = `快递绑定库位`

        this.$refs.refLogisticSubscribeExport.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
        this.$refs.refLogisticSubscribeExport.hideLoading()
      }
    }
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    // this.handleQuery()
  }
}
</script>

<style scoped>

</style>